import "./birgesPage.css"
import ArrowButtonGoBack from "../../components/arrowButtonGoBack/arrowButtonGoBack"
import Burger from "../../components/burger/burger"
import birgesImg from "../../img/birgesImg.png";
import birgesImg2 from "../../img/birgesImg2.png";
import birgesImg3 from "../../img/birgesImg3.png";
import birgesImg4 from "../../img/birgesImg4.png";
import birgesImg5 from "../../img/birgesImg5.png";
import AnimatedPage from "../animationPage"
import birgesPart1 from "../../img/birgesPart1.png"
import { useEffect, useState, useRef} from "react";
export default function BirgesPage(){
    const LogoRef = useRef(null)
    const firstInput = useRef(null)
    const secInput = useRef(null)
    const buttonIn = useRef(null)
    const forgotPass = useRef(null)
    const forgot = useRef(null)
    useEffect(()=>{
        setTimeout(() => {
            LogoRef.current.style.transform="rotateZ(360deg) scale(1)"
            firstInput.current.style.transform="rotateZ(360deg) scale(1)"
            secInput.current.style.transform="rotateZ(360deg) scale(1)"
            buttonIn.current.style.transform="rotateZ(360deg) scale(1)"
            forgotPass.current.style.transform="rotateZ(360deg) scale(1)"
            forgot.current.style.transform="rotateZ(360deg) scale(1)"
        }, 500);
        setTimeout(() => {
            firstInput.current.style.animation="wiggle 6s linear infinite"
            secInput.current.style.animation="wiggle 6s linear infinite"
            secInput.current.style.animationDelay="0.7s"
            buttonIn.current.style.animation="wiggle 6s linear infinite"
            buttonIn.current.style.animationDelay="1.4s"
            forgotPass.current.style.animation="wiggle 6s linear infinite"
            forgotPass.current.style.animationDelay="2.1s"
            forgot.current.style.animation="wiggle 6s linear infinite"
            forgot.current.style.animationDelay="2.8s"
        }, 5000);
    },[])
    const goPoocoin = () =>{
        window.open("https://poocoin.app/tokens/0xd983ab71a284d6371908420d8ac6407ca943f810","_blank")
    }
    const goSuperex = () =>{
        window.open("https://www.superex.com/trade/ULX_USDT","_blank")
    }
    const goUniswap = () =>{
        window.open("https://app.uniswap.org/#/swap?chain=mainnet","_blank")
    }
    const goPancake = () =>{
        window.open("https://pancakeswap.finance/swap?inputCurrency=0xd983ab71a284d6371908420d8ac6407ca943f810&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56","_blank")
    }
    const goUltSwap = () =>{
        window.open("https://ultronswap.com/","_blank")
    }
    return(
        <AnimatedPage>
        <div className="birgesPage_container">
            <ArrowButtonGoBack/>
            <Burger/>
            <p className="birgesPage_title">биржи</p>
            <img ref={LogoRef} className="birgesImg" src={birgesImg}/>
            <div className="birges_row">
                <a onClick={goUltSwap} href="" className="birges_partOne">
                    <img className="birges_first_animation" ref={firstInput} src={birgesPart1}/>
                    <p className="part_title">UltronSwap</p>
                    <p className="part_text">Собственная биржа</p>
                    <a onClick={goUltSwap} href="">перейти</a>
                </a>
                <a onClick={goPancake} href="" className="birges_partTwo">
                    <img className="birges_sec_animation" ref={secInput} src={birgesImg2}/>
                    <p className="part_title">PancakeSwap</p>
                    <p className="part_text">Поиск Ulx</p>
                    <a onClick={goPancake} href="">перейти</a>
                </a>
            </div>
            <div className="birges_row">
                <a onClick={goUniswap} href="" className="birges_partThree">
                    <img ref={buttonIn} className="birgesImg3" src={birgesImg3}/>
                    <p className="part_title">uniswap</p>
                    <a onClick={goUniswap} href="">перейти</a>
                </a>
                <a onClick={goSuperex} href="" className="birges_partThree">
                    <img ref={forgotPass} className="birgesImg4" src={birgesImg4}/>
                    <p className="part_title">шаги тут</p>
                    <a onClick={goSuperex} href="">перейти</a>
                </a>
                <a onClick={goPoocoin} href="" className="birges_partThree">
                    <img ref={forgot} className="birgesImg5" src={birgesImg5}/>
                    <p className="part_title">poocoin</p>
                    <a onClick={goPoocoin} href="">перейти</a>
                </a>
            </div>
            <div className="down_padding"></div>
        </div>
        </AnimatedPage>
    )
}