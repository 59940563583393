import "./promotionPage.css"
import { Link } from "react-router-dom";
import StepsBlock from "../../components/stepsBlock/StepsBlock";
import promotionImg1 from "../../img/promotionImg1.png";
import promotionImg2 from "../../img/promotionImg2.png";
import promotionImg3 from "../../img/promotionImg3.png";
import promotionImg4 from "../../img/promotionImg4.png";
import promotionImg5 from "../../img/promotionImg5.png";
import ArrowButtonDataBase from "../../components/arrowButtonDataBase/arrowButtonDataBase";
import AnimatedPage from "../animationPage";


export default function PromotionPage(){
    var requestArr={
        sections: [
            {
                id: 0,
                title: "Как работает автоматизация",
                number: "1",
                imgBig: promotionImg1,
                flexDirection: "row",
                link: "/promotionHowItWorks",
            },
            {
                id: 1,
                title: "автоматизация с чего начать",
                number: "2",
                imgBig: promotionImg2,
                flexDirection: "row-reverse",
                link: "/promotionWhereToStart",
            },
            {
                id: 2,
                title: "продающие посты",
                number: "3",
                imgBig: promotionImg3,
                flexDirection: "row",
                link: "/promotionSellingPosts",
            },
            {
                id: 3,
                title: "маркетинг mavie",
                number: "4",
                imgBig: promotionImg4,
                flexDirection: "row-reverse",
                link: "/promotionMarketingMavie",
            },
            {
                id: 4,
                title: "слайды презентаций",
                number: "5",
                imgBig: promotionImg5,
                flexDirection: "row",
                link: "/promotionPresentation",
            },
        ],
    }
    return(
        <AnimatedPage>
        <div className="promotion_page_container">
            <ArrowButtonDataBase/>
            <p className="promotion_page_title">продвижение</p>
            {requestArr.sections.map(requestArrs =>(
                <StepsBlock key={requestArrs.id} title={requestArrs.title} number={requestArrs.number} imgBig={requestArrs.imgBig} link={requestArrs.link} flexDirection={requestArrs.flexDirection}/>
            ))}
            <div className="down_padding"></div>
        </div>  
        </AnimatedPage> 
    )
}