import "./welcomePage.css";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef} from "react";
import ultronLogo from "../../img/UltronLogo.png"
import regCompleteArrow from "../../img/regCompleteArrow.png"
import welcomeImg from "../../img/welcomeImg.png"
import copyIcon from "../../img/copyIcon.png"
import AnimatedPage from "../animationPage";
import mansitastable from "../../img/mansitastable.png"
import welcomeImg1 from "../../img/welcomeImg1.png"
import welcomeImg3 from "../../img/welcomeImg3.png"
import welcomeImg4 from "../../img/welcomeImg4.png"
import welcomeStructures from "../../img/welcomeStructures.png"
import { apiUrl } from "../../const";
export default function WelcomePage(){
    const innerHTML = useRef(null)
    const LogoRef = useRef(null)
    const firstInput = useRef(null)
    const secInput = useRef(null)
    const thirdInput = useRef(null)
    const fourthInput = useRef(null)
    const buttonIn = useRef(null)
    const forgotPass = useRef(null)
    const [activatedID,setActivatedID]=useState({
        id:"",
        activated:"",
    })
    const [doWeHaveFather, setDoWeHaveFather]=useState(false);
    const [fatherRefLink, setFatherRefLink]=useState("");
    useEffect(()=>{
        const data = new FormData();
        data.append("tel", localStorage.tel);
        data.append("password", localStorage.password);
        fetch(apiUrl+"/getID.php", {
                method: "post",
                body: data,
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let arr=data.split(",")
                setActivatedID({id:arr[0],activated:arr[1]})
                innerHTML.current.value=arr[0]
                console.log(data)
                localStorage.setItem("ID",arr[0])
                localStorage.setItem("isActivated",arr[1])
            });

        fetch(apiUrl+"/getFatherLink.php", {
                method: "post",
                body: data,
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setFatherRefLink(data);
            });
        fetch(apiUrl+"/getFather.php", {
                method: "post",
                body: data,
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if(data!="no"){
                    setDoWeHaveFather(true);
                }
                else{
                }
            });
        setTimeout(() => {
            LogoRef.current.style.transform="rotateZ(360deg) scale(1)"
            firstInput.current.style.transform="scale(1)"
            secInput.current.style.transform="scale(1)"
            thirdInput.current.style.transform="scale(1)"
            fourthInput.current.style.transform="scale(1)"
            buttonIn.current.style.transform="scale(1)"
            forgotPass.current.style.transform="scale(1)"
        }, 500);
    },[])
    console.log(activatedID)
    const hiddenInput = useRef(null)
    const copyTextBackgroundRef=useRef(null)
    const copytext = (e)=>{
        innerHTML.current.select();
        document.execCommand('copy');
        copyTextBackgroundRef.current.style.background="rgba(86, 26, 211, 0.4)"
        setTimeout(() => {
            copyTextBackgroundRef.current.style.background="#EAE5F3"
        }, 1000);
    }
    console.log(doWeHaveFather)
    return(
        <AnimatedPage>
        <div className="welcome_page_container">

            <p className="welcome_title">добро пожаловать!</p>
            <img ref={LogoRef} className="ultronLogo" src={ultronLogo}/>
            <div className="welcome_links_box">
                <div className="welcome_links_left_column">
                    <Link to="/databaseUltron" ref={firstInput} className="database_link">
                        <img className="database_link_img database_link_img1" src={welcomeImg1}/>
                        <p>база знаний ultron</p>
                    </Link>
                    <Link to="/databaseMavie" ref={thirdInput} className="database_link">
                        <img className="database_link_img database_link_img2" src={welcomeImg4}/>
                        <p>база знаний mavie</p>
                    </Link>
                </div>
                <div className="welcome_links_column">
                    <Link ref={fourthInput} to="/profileStructures" className="konkurses_link">
                        <img className="konkurses_link_img" src={welcomeStructures}/>
                        <p>структура</p>
                    </Link>
                    <Link ref={secInput} to="/documents" className="konkurses_link">
                        <img className="konkurses_link_img" src={welcomeImg3}/>
                        <p>документы</p>
                    </Link>
                    <Link ref={buttonIn} to="/profile" className="konkurses_link">
                            <img className="konkurses_link_img" src={mansitastable}/>
                            <div className="arrow_animated_on_welcome_page_box">
                                <img src={regCompleteArrow} className="arrow_animated_on_welcome_page"/>
                            </div>
                            <p>профиль</p>
                    </Link>
                </div>
            </div>
            {activatedID.activated=="1" ? 
                <div ref={forgotPass} className="referal_box_welcome refDelay1">
                    <p>ваш персональный код</p>
                    <div className="referal_link_welcome">
                        <div className="personal_ID" ref={copyTextBackgroundRef} style={activatedID.activated=="1"?{opacity:"1"}:{opacity:"0.5", pointerEvents:"none"}}>
                            <div className="personal_ID_input_box">код <input ref={innerHTML} readOnly></input></div>
                            <img className="copyIcon" onClick={copytext} src={copyIcon}/>
                        </div>
                        {activatedID.activated=="1" ? <p className="give_that_code" >Назовите этот код человеку  которого вы хотите пригласить</p> : <p className="give_that_code" >ID станет доступен после одобрения</p>}
                    </div>
                </div>
            :
            doWeHaveFather==true ? 
            <div ref={forgotPass} className="referal_box_welcome2 refDelay1">
                <p>присоединиться к команде MAVIE</p>
                <div className="referal_link_welcome2">
                    <a className="referal_link_father" href={fatherRefLink}>Перейти</a>
                    <p className="referal_link_father_text">пройдите регистрацию, <br/> если вы еще не являетесь<br/> партнером mavie.</p>
                </div>
            </div>
            :
            <div ref={forgotPass} className="referal_box_welcome refDelay1">
                <p>ваш персональный код</p>
                <div className="referal_link_welcome">
                    <div className="personal_ID" style={activatedID.activated=="1"?{opacity:"1"}:{opacity:"0.5", pointerEvents:"none"}}>
                        <div className="personal_ID_input_box">код <input ref={innerHTML} readOnly></input></div>
                        <img className="copyIcon" onClick={copytext} src={copyIcon}/>
                    </div>
                    {activatedID.activated=="1" ? <p className="give_that_code" >Назовите этот код человеку  которого вы хотите пригласить</p> : <p className="give_that_code" >ID станет доступен после одобрения</p>}
                </div>
            </div>
            }

            <div className="down_padding">
            </div>
            <textarea id="hidden_input" ref={hiddenInput} className="hidden_input"></textarea>
        </div>
        </AnimatedPage>
    )
}