import "./registrationPage.css";
import ultronLogo from "../../img/UltronLogo.png";
import ultronHelp from "../../img/help.svg";
import { Link } from "react-router-dom";
import AnimatedPage from "../animationPage";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import helpIcon from "../../img/helpIcon.png"
import { apiUrl } from "../../const";
export default function RegistrationPage() {
  const Navigate = useNavigate();
  const [form, setForm] = useState({
    tel: "",
    password: "",
    fio: "",
    code: "",
  });
  const [errorText, seterrorText] = useState("");
  const [telEntry, setTelEntry] = useState(false);
  const [telErrorText, setTelErrorText] = useState("");
  const [passEntry, setPassEntry] = useState(false);
  const [passErrorText, setPassErrorText] = useState("");
  const [fioEntry, setFioEntry] = useState(false);
  const [fioErrorText, setFioErrorText] = useState("");
  const [codeError, setCodeError] = useState("");
  const [refEntry, setRefEntry] = useState(false);
  const [refErrorText, setRefErrorText] = useState("");
  const handleChange = (event) => {
    switch (event.target.name) {
      case "tel":
        mask(event);
        if (event.target.value.length == 17) {
          setTelEntry(true);
        } else {
          setTelEntry(false);
        }
        setForm({
          ...form,
          tel: event.target.value,
        });
        break;
      case "password":
        if (event.target.value.length >= 6) {
          setPassEntry(true);
        } else {
          setPassEntry(false);
        }
        setForm({
          ...form,
          password: event.target.value,
        });
        break;
      case "fio":
        if (event.target.value.length >= 5) {
          setFioEntry(true);
        } else {
          setFioEntry(false);
        }
        setForm({
          ...form,
          fio: event.target.value,
        });
        break;
      case "code":
        if (event.target.value.length >= 2) {
          setRefEntry(true);
        } else {
          setRefEntry(false);
        }
        setForm({
          ...form,
          code: event.target.value,
        });
    }
  };
  function mask(event) {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    let pos = event.target.selectionStart;
    if (pos < 3) event.preventDefault();
    let matrix = "+7 (___) ___ ____",
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, ""),
      new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });
    i = new_value.indexOf("_");
    if (i != -1) {
      i < 5 && (i = 3);
      new_value = new_value.slice(0, i);
    }
    let reg = matrix
      .substr(0, event.target.value.length)
      .replace(/_+/g, function (a) {
        return "\\d{1," + a.length + "}";
      })
      .replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (
      !reg.test(event.target.value) ||
      event.target.value.length < 5 ||
      (keyCode > 47 && keyCode < 58)
    )
      event.target.value = new_value;
    if (event.type == "blur" && event.target.value.length < 5)
      event.target.value = "";
  }
  const registration = () => {
    if (telEntry && passEntry && fioEntry && form.code.length > 0) {
      let formData = new FormData();
      formData.append("code", form.code);
      fetch("https://come-back-team.store/checkCode.php", {
        method: "POST",
        body: formData,
      })
        .then((respose) => {
          return respose.json();
        })
        .then((data) => {
          if (data == "0") {
            setRefErrorText("Неверный код");
          } else {
            let arr = data.split(",");
            localStorage.setItem("nasTel", arr[1]);
            localStorage.setItem("nasFio", arr[0]);
            localStorage.setItem("telReg", form.tel);
            localStorage.setItem("passwordReg", form.password);
            localStorage.setItem("fioReg", form.fio);
            localStorage.setItem("codeReg", form.code);
            localStorage.setItem("tel", form.tel);
            localStorage.setItem("password", form.password);
            localStorage.setItem("fio", form.fio);
            localStorage.setItem("code", form.code);
            Navigate("/registrationComplete");
          }
        });
    }
    if (form.code.length == 0) {
      setRefErrorText("Вы не указали код");
    }
    if (!telEntry) {
      setTelErrorText("Телефон указан неправильно");
    } else {
      setTelErrorText("");
    }
    if (!passEntry) {
      setPassErrorText("Пароль < 6 символов");
    } else {
      setPassErrorText("");
    }
    if (!fioEntry) {
      setFioErrorText("Укажите настоящее имя");
    } else {
      setFioErrorText("");
    }
  };
  const LogoRef = useRef(null);
  const firstInput = useRef(null);
  const secInput = useRef(null);
  const thirdInput = useRef(null);
  const fourthInput = useRef(null);
  const buttonIn = useRef(null);
  const buttonReg = useRef(null);
  const helpRef = useRef(null);
  useEffect(() => {
    localStorage.clear();
    localStorage.setItem("preloaderPlayed", "true");
    setTimeout(() => {
      LogoRef.current.style.transform = "rotateZ(360deg) scale(1)";
      firstInput.current.style.transform = "scale(1)";
      secInput.current.style.transform = "scale(1)";
      buttonIn.current.style.transform = "scale(1)";
      thirdInput.current.style.transform = "scale(1)";
      fourthInput.current.style.transform = "scale(1)";
      buttonReg.current.style.transform = "scale(1)";
      helpRef.current.style.transform = "scale(1)";
    }, 500);
  }, []);
  return (
    <AnimatedPage>
      <div className="registration_page_container">
        <p className="registration_title">регистрация</p>
        <p className="registration_info">
          <b>зарегистрируйтесь</b>, чтобы воспользоваться всеми возможностями
          нашего приложения
        </p>
        <img ref={LogoRef} className="ultron_logo" src={ultronLogo} />
        <div className="input_tel">
          <div className="already_exists_phone">{fioErrorText}
          </div>
            <a ref={helpRef} href="https://t.me/+fQ0eKoCh8rUwZmQy" alt="" className="help_button_on_login">
                <img src={helpIcon}/>
                <p>помощь</p>
            </a> 
          <input
            ref={firstInput}
            type="text"
            name="fio"
            className="first_input full_inputs"
            value={form.fio}
            onChange={handleChange}
            placeholder="ИМЯ и фамилия"
          ></input>
        </div>
        <div className="input_tel">
          <div className="already_exists_phone">{telErrorText}</div>
          <input
            ref={secInput}
            type="tel"
            name="tel"
            className="sec_input tel_inputs"
            value={form.tel}
            onChange={handleChange}
            placeholder="ТЕЛЕФОН"
          ></input>
          <div className="short_pass">{passErrorText}</div>
          <input
            ref={thirdInput}
            type="password"
            name="password"
            className="third_input tel_inputs"
            value={form.password}
            onChange={handleChange}
            placeholder="ВАШ ПАРОЛЬ"
          ></input>
        </div>
        <div className="invited_id_box">
          <div className="referal_short">{refErrorText}</div>
          <input
            ref={fourthInput}
            type="text"
            name="code"
            className="fourth_input full_inputs"
            value={form.code}
            onChange={handleChange}
            placeholder="Код авторизации бота"
          ></input>
        </div>
        <button
          ref={buttonIn}
          className="button_registration_on"
          onClick={registration}
        >
          зарегистрироваться
        </button>
        <Link to="/">
          <button ref={buttonReg} className="button_back_to_login">
            вернуться к входу
          </button>
        </Link>
      </div>
    </AnimatedPage>
  );
}
