import arrowImg from "../../../img/arrowback.png"
import { Link } from "react-router-dom"


export default function ArrowButtonWhereToStart(){
    const WindowBack=()=>{
        window.history.back()
    }
    return(
            <div className="arrow_button_go_back_container" onClick={WindowBack}>
                <img src={arrowImg}/>
                <p>с чего начать?</p>
            </div>
    )
}