import "./burgerMenu.css"
import burgerIcon from "../../img/menuburger2.png";
import { Link } from "react-router-dom";
import UltronMiniLogo from "../../img/ultronMiniLogo.png"
export default function BurgerMenu(props){
    const setMenuOpener = props.setMenuOpener;
    const burgerRef = props.burgerRef;
    const closeMenu=()=>{
        window.document.getElementsByClassName("burger_menu_container")[0].style="background: rgba(0, 0, 0, 0); width: 0vw";
        window.document.getElementsByClassName("burger_menu_links")[0].style.right="-50vw";
        burgerRef.current.style.transform="rotateZ(0deg)"
        setMenuOpener(false)
    }
    const closeMenuSec=()=>{
        localStorage.clear()
        window.document.getElementsByClassName("burger_menu_container")[0].style="background: rgba(0, 0, 0, 0); width: 0vw";
        window.document.getElementsByClassName("burger_menu_links")[0].style.right="-50vw";
        burgerRef.current.style.transform="rotateZ(0deg)"
        setMenuOpener(false)
        localStorage.setItem("preloaderPlayed","true")
    }
    document.addEventListener("click", (event)=>{
        if(event.target.className=="burger_menu_container"){
            closeMenu()
        }
    })
    return(
        <div className="burger_menu_container">
            <div className="burger_menu_links">
                <Link to="/welcome" onClick={closeMenu}><li className="right_links">главная <div className="list_dot"></div></li></Link>
                <Link to="/database" onClick={closeMenu}><li className="right_links">база знаний <div className="list_dot"></div></li></Link>
                <Link to="/konkurses" onClick={closeMenu}><li className="right_links">конкурсы <div className="list_dot"></div></li></Link>
                <Link to="/profile" onClick={closeMenu}><li className="right_links">профиль <div className="list_dot"></div></li></Link>
                <Link to="/profileSettings" onClick={closeMenu}><li className="right_links">настройки <div className="list_dot"></div></li></Link>
                <Link to="/" onClick={closeMenuSec}><li className="right_links">выйти <div className="list_dot"></div></li></Link>
                <img src={UltronMiniLogo} className="ultronMiniLogo"/>
            </div>
        </div>
    )
}