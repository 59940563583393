import "./presentationUltron.css"
import ArrowButtonWhereToStart from "../arrowButtonWhereToStart/arrowButtonWhereToStart"
import AnimatedPage from "../../animationPage"
import { Link } from "react-router-dom";
import presentationsImg1 from "../../../img/presentationsImg1.png"
import presentationsImg2 from "../../../img/presentationsImg2.png"
import presentationsImg3 from "../../../img/presentationsImg3.png"
import presentationsImg4 from "../../../img/presentationsImg4.png"
import presentationsImg5 from "../../../img/presentationsImg5.png"
import arrowToRight from "../../../img/arrowToRight.png"
export default function PresentationUltron(){
    return(
        <AnimatedPage>
        <div className="presentation_ultron_container">
            <ArrowButtonWhereToStart/>
            <p className="presentation_title">Презентации Ultron</p>
            <p className="presentation_text"><b>Здесь мы собрали информацию о</b><br/> компании Ultron и маркетингового<br/> агентства Mavie.</p>
            
            <Link to="/presentationReasonBlockChain" className="presentation_button_box">
                <img src={presentationsImg1}/>
                <p>суть блокчейна, <br/> почему ultron?</p>
                <div className="arrow_button_presentation_box">
                    <img src={arrowToRight}/>
                </div>
            </Link>
            <Link to="/presentationEcoSystem" className="presentation_button_box">
                <img src={presentationsImg2}/>
                <p>эко система <br/>блокчейна ultron?</p>
                <div className="arrow_button_presentation_box">
                    <img src={arrowToRight}/>
                </div>
            </Link>
            <Link to="/presentationHowMakeMoney" className="presentation_button_box">
                <img src={presentationsImg3}/>
                <p>как зарабатывать <br/> usdt в mavie</p>
                <div className="arrow_button_presentation_box">
                    <img src={arrowToRight}/>
                </div>
            </Link>
            <Link to="/presentationPromotionNewbies" className="presentation_button_box">
                <img src={presentationsImg4}/>
                <p>промоушены для <br/> первопроходцев</p>
                <div className="arrow_button_presentation_box">
                    <img src={arrowToRight}/>
                </div>
            </Link>
            <Link to="/presentationVebinars" className="presentation_button_box">
                <img src={presentationsImg5}/>
                <p>вебинары <br/> с руководителями</p>
                <div className="arrow_button_presentation_box">
                    <img src={arrowToRight}/>
                </div>
            </Link>
        </div>
        </AnimatedPage>
    )
}