import "./presentationUltron.css"
import videoPlaceholder from "../../../img/videoPlaceholder.png"
import ArrowButtonPresentation from "./arrowButtonPresentation"
import AnimatedPage from "../../animationPage"
export default function PresentationVebinars(){
    return(
        <AnimatedPage>
        <div className="presentations_containerBig">
            <ArrowButtonPresentation/>
            <div className="presentation_titles">Вебинары с руководителями</div>
            <div className="vebinars_part">
                <iframe width="99%" height="187" src="https://www.youtube.com/embed/6Hzh5WGHHrg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="presentations_vebinars_text"><div className="left_numbers_circles_vebinars">1</div>Интервью с <br/>Маликом и Клеменом. <br/> ТОП лидер Ultron и <br/>директор по маркетингу Mavie.</div>
            </div>
            <div className="vebinars_part">
                <iframe width="99%" height="187" src="https://www.youtube.com/embed/Ts1TXN6OYhg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="presentations_vebinars_text"><div className="left_numbers_circles_vebinars">2</div><span>1. Презентация блокчейна Ultron.<br/> 2. Обзор системы Mavie. <br/> 3. Как работает фарминг. <br/>4. Обзор автоматизации.</span></div>
            </div>
            <div className="vebinars_part">
                <iframe width="99%" height="185" src="https://www.youtube.com/embed/swWbDfL1Bzw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="presentations_vebinars_text"><div className="left_numbers_circles_vebinars">3</div>Мероприятие в Solidar Club как мы и запланировали, наша команда пригласила топ лидера Mavie Ultron, который сделал более 10.000.000$ оборот.</div>
            </div>
            <div className="scroll_down_container">
                <div className="scroll_down">
                    Листайте вниз
                </div>
            </div>
            <div className="down_padding"></div>
        </div>
        </AnimatedPage>
    )
}