import "./arrowButtonStart.css"
import arrowImg from "../../img/arrowback.png"
import { Link } from "react-router-dom"


export default function ArrowButtonStart(){
    const WindowBack=()=>{
        window.history.back()
    }
    return(
            <div className="arrow_button_back_container" onClick={WindowBack}>
                <img src={arrowImg}/>
                <p>назад</p>
            </div>
    )
}