import "./profilePage.css";
import Burger from "../../components/burger/burger";
import { Link } from "react-router-dom";
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart";
import profilePhotoImg from "../../img/profilephoto.png";
import pencilImg from "../../img/pencil.png";
import QRarrows from "../../img/QRarrows.png"
import AnimatedPage from "../animationPage";
import {QRCodeSVG} from 'qrcode.react';
import { apiUrl } from "../../const";
import buttonToProfileSettings from "../../img/buttonToProfileSettings.png"
import buttonToProfileStructures from "../../img/buttonToProfileStructures.png"
import { useEffect, useState, useRef} from "react";
import qrcodeplaceholderArrows from "../../img/qrcodeplaceholderArrows.png"
export default function ProfilePage(){
    const LogoRef = useRef(null)
    const firstInput = useRef(null)
    const secInput = useRef(null)
    const buttonIn = useRef(null)
    const forgotPass = useRef(null)
    const forgot = useRef(null)
    const headerButtonLeft = useRef(null)
    const headerButtonRight = useRef(null)
    const isActivated = localStorage.isActivated;
    useEffect(()=>{
        setTimeout(() => {
            LogoRef.current.style.transform="rotateZ(360deg) scale(1)"
            headerButtonLeft.current.style.transform="scale(1)"
            headerButtonRight.current.style.transform="scale(1)"
            firstInput.current.style.transform="scale(1)"
            secInput.current.style.transform="scale(1)"
            buttonIn.current.style.transform="scale(1)"
            forgotPass.current.style.transform="scale(1)"
            forgot.current.style.transform="scale(1)"
        }, 500);
        if(localStorage.imgPath){
            setImgPath(localStorage.imgPath)
        }
        if(localStorage.link){
            setQrcodelink(localStorage.link);
            MavieRef.current.value=localStorage.link;
        }
    },[])    
    const fileName = useRef(null)
    const [imgPath, setImgPath]=useState("")
    const [saveOrCopy, setSaveOrCopy]=useState("")
    function onChange() {
        const data = new FormData();
        data.append("tel", localStorage.tel);
        data.append("password", localStorage.password);
        data.append("file", fileName.current.files[0]);
        fetch(apiUrl+"/avatar.php", {
            method: "post",
            body: data,
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if(data){
                localStorage.setItem("imgPath",data)
                setImgPath(data)
            }
            const datar = new FormData();
            datar.append("tel", localStorage.tel);
            datar.append("password", localStorage.password);
            datar.append("link", data);
            fetch(apiUrl+"/updateImg.php", {
                method: "post",
                body: datar,
            })
        });
        console.log(fileName.current.files[0])
    }
    const updateLink = () => {
        let data = new FormData();
        data.append("tel", localStorage.tel);
        data.append("password", localStorage.password);
        data.append("link", MavieRef.current.value);
        fetch(apiUrl+"/updateLink.php", {
          body: data,
          method: "post",
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if(data!="error"){
                localStorage.link=MavieRef.current.value
            }
            console.log(data);
          });
    };
    const [qrcodeLink, setQrcodelink]=useState("")
    const MavieRef=useRef(null)
    const saveReferalQR=()=>{
        console.log(MavieRef.current.value)
        localStorage.setItem("link", MavieRef.current.value)
        setQrcodelink(MavieRef.current.value);
        updateLink();
    }
    const copytext = (e)=>{
        MavieRef.current.select();
        document.execCommand('copy');
        e.target.style.background="rgba(86, 26, 211, 0.6)"
        setTimeout(() => {
            e.target.style.background="rgba(59, 225, 0, 1)"
        }, 1000);
    }
    return(
        <AnimatedPage>
        <div className="profile_page_container">
            <ArrowButtonStart/>

            <p className="profile_title">профиль</p>
            <div className="profile_header_buttons">
                <div className="header_button" ref={headerButtonLeft}>
                    <Link to="/profileStructures"><img className="buttonToProfileSettings" src={buttonToProfileStructures}/></Link>
                    <p className="buttonToName">структура</p>
                </div>
                <div ref={LogoRef} className="profile_photo_box">
                    <img className="profile_photo" src={imgPath.length>0 ? imgPath : profilePhotoImg}/>
                    <form encType="multipart/form-data" action="URL" method="POST">
                        <input type="file" name="file" onChange={onChange} ref={fileName} style={{display:"none"}} id="avatar"></input>
                        <label htmlFor="avatar"><img className="pencil_photo_profile" src={pencilImg}/></label>
                    </form>
                </div>
                <div className="header_button" ref={headerButtonRight}>
                    <Link to="/profileSettings"><img src={buttonToProfileSettings} className="buttonToProfileSettings"/></Link>
                    <p className="buttonToName">настройки</p>
                </div>
            </div>

            <input ref={firstInput} className="firstInput" placeholder="имя" value={localStorage.name}></input>
            <input ref={buttonIn} className="thirdInput" placeholder="телефон" value={localStorage.tel}></input>
            <button ref={secInput} className="secInput">Сохранить</button>
            <div ref={forgotPass} className="referal_box refDelay2">
                <p>ваша реферальная ссылка</p>
                <div className="referal_link">
                    {localStorage.link=="" ? <input ref={MavieRef} className="referal_link_sec_input" placeholder={"Введите ссылку MAVIE"}></input> : <input readOnly ref={MavieRef} className="referal_link_input"></input>}
                    {localStorage.link=="" ? <button onClick={saveReferalQR}>сохранить</button> : <button onClick={copytext}>копировать</button> }
                </div>
            </div>
            <div ref={forgot} className="qr_code_box">
                {qrcodeLink!="" ?
                <QRCodeSVG value={qrcodeLink} />
                :
                <div className="qrcode_placeholder_box">
                    <div className="qrcode_placeholder">
                        <b>Введите ссылку </b><br/> и ваш QR код сформируется автоматически
                    </div>
                    <img className="qrcodeplaceholderArrows" src={qrcodeplaceholderArrows}/>
                </div>
                }
                
                    <div className="your_qr_code">
                        <p>ваш QR код</p>
                        <img src={QRarrows}/>
                    </div>
                </div>
            <div className="down_padding">
            </div>
        </div>
        </AnimatedPage>
    )
}