import "./loginPage.css";
import ultronLogo from "../../img/UltronLogo.png";
import { Link } from "react-router-dom";
import AnimatedPage from "../animationPage";
import { useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../const";
import helpIcon from "../../img/helpIcon.png"
export default function LoginPage(){
    const Navigate = useNavigate()
    const [form, setForm] = useState({
        tel: '',
        password: '',       
    })
    const [errorText, seterrorText] = useState("")
    const [buttonColor, setButtonColor] = useState(false)
    const handleChange = (event) => {

        switch (event.target.name) {
            case "tel":
                mask(event)
                setForm(
                    {
                        ...form,
                        tel: event.target.value
                    }
                );
                if(firstInput.current.value.length==17&&secInput.current.value.length>=6){
                    setButtonColor(true)
                }
                else{
                    setButtonColor(false)
                }
                break;
            case "password":
                setForm(
                    {
                        ...form,
                        password: event.target.value
                    }
                );
                if(firstInput.current.value.length==17&&secInput.current.value.length>=6){
                    setButtonColor(true)
                }
                else{
                    setButtonColor(false)
                }
                break;
        }
    }
    function mask(event) {
        let keyCode;
        event.keyCode && (keyCode = event.keyCode);
        let pos = event.target.selectionStart;
        if (pos < 3) event.preventDefault();
        let matrix = "+7 (___) ___ ____",
          i = 0,
          def = matrix.replace(/\D/g, ""),
          val = event.target.value.replace(/\D/g, ""),
          new_value = matrix.replace(/[_\d]/g, function (a) {
            return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
          });
        i = new_value.indexOf("_");
        if (i != -1) {
          i < 5 && (i = 3);
          new_value = new_value.slice(0, i);
        }
        let reg = matrix
          .substr(0, event.target.value.length)
          .replace(/_+/g, function (a) {
            return "\\d{1," + a.length + "}";
          })
          .replace(/[+()]/g, "\\$&");
        reg = new RegExp("^" + reg + "$");
        if (
          !reg.test(event.target.value) ||
          event.target.value.length < 5 ||
          (keyCode > 47 && keyCode < 58)
        )
          event.target.value = new_value;
        if (event.type == "blur" && event.target.value.length < 5)
          event.target.value = "";
      }
    const LogoRef = useRef(null)
    const firstInput = useRef(null)
    const secInput = useRef(null)
    const buttonIn = useRef(null)
    // const forgotPass = useRef(null)
    const buttonReg = useRef(null)
    const helpRef = useRef(null);
    useEffect(()=>{
        if(localStorage.telReg){
            Navigate("/registrationComplete")
        }
        if(localStorage.tel&&localStorage.password){
            form.tel=localStorage.tel;
            form.password=localStorage.password;
            auth()
        }
        setTimeout(() => {
            LogoRef.current.style.transform="rotateZ(360deg) scale(1)"
            firstInput.current.style.transform="scale(1)"
            secInput.current.style.transform="scale(1)"
            buttonIn.current.style.transform="scale(1)"
            // forgotPass.current.style.transform="scale(1)"
            buttonReg.current.style.transform="scale(1)"
            helpRef.current.style.transform = "scale(1)";
        }, 500);
        
    },[])
    const auth = () =>{
        const data = new FormData();
        data.append("tel", form.tel);
        data.append("password", form.password); 

        fetch(apiUrl+"/auth.php", {
                method: "post",
                body: data,
            })
                .then((response) => {
                return response.json();
                })
                .then((data) => {
                    console.log(data)
                if (data != "error"){
                    let arr=data.split(",")
                    localStorage.setItem("name",arr[0])
                    localStorage.setItem("link",arr[1])
                    localStorage.setItem("imgPath",arr[2])
                    localStorage.setItem("tel",form.tel)
                    localStorage.setItem("password",form.password)
                    const datar = new FormData();
                    datar.append("tel", form.tel);
                    datar.append("password", form.password);
                    fetch(apiUrl+"/getID.php", {
                            method: "post",
                            body: datar,
                        })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            let arr=data.split(",")
                            localStorage.setItem("ID",arr[0])
                            localStorage.setItem("isActivated",arr[1])
                            if(arr[1]==1){
                                Navigate("/welcome")
                            }
                            else{
                                Navigate("/registrationComplete")
                            }
                        });
                }
                else{
                    seterrorText("Неверные данные для входа")
                }
            });
    }
    return(
        <AnimatedPage>
        <div className="login_page_container">
            <p className="login_title">вход</p>
            <p className="login_info"><b>зарегистрируйтесь</b>, чтобы воспользоваться всеми возможностями нашего приложения</p>
            <img className="ultron_logo" ref={LogoRef} src={ultronLogo}/>
            <div className="input_tel">
                <div className="already_exists_phone">{errorText}</div>
                <a ref={helpRef} href="https://t.me/+fQ0eKoCh8rUwZmQy" alt="" className="help_button_on_login">
                    <img src={helpIcon}/>
                    <p>помощь</p>
                </a> 
            </div>
            <input ref={firstInput} type="tel" placeholder="ТЕЛЕФОН привязанный к telegram" name="tel" value={form.tel} onChange={handleChange}></input>
            <input ref={secInput} type="password" placeholder="ВАШ ПАРОЛЬ" name="password" value={form.password} onChange={handleChange}></input>
            <div className="button_login_on_box" ref={buttonIn}>
                <button className="button_login_on" style={buttonColor?{background: "rgba(112, 46, 247, 1)",color:"rgba(255, 255, 255, 1)"}:{background: "rgba(234, 229, 243, 1)",color:"rgba(170, 161, 188, 1)"}} onClick={auth}>войти</button>
            </div>
            {/* <button ref={forgotPass} className="forgot_password">забыли пароль?</button> */}
            <Link to="/registration"><button ref={buttonReg} className="button_go_to_registration">регистрация</button></Link>
            <p className="ultron_text_logo">ultron</p>
        </div>
        </AnimatedPage>
    )
}