import "./wantToJoinBranch.css"
import ArrowButtonWantToJoin from "./arrowButtonWantToJoin"
import AnimatedPage from "../animationPage"
import videoPlaceholder from "../../img/videoPlaceholder.png"
import wantToJoinRegister1 from "../../img/wantToJoinRegister1.png"
import wantToJoinRegister2 from "../../img/wantToJoinRegister2.png"
import wantToJoinRegister3 from "../../img/wantToJoinRegister3.png"
export default function WantToJoinClubBalance(){
    return(
        <AnimatedPage>
        <div className="want_to_join_containers">
            <ArrowButtonWantToJoin/>
            <p className="want_to_join_club_balance_title">club balance: как он работает</p>
            <iframe width="99%" height="173" src="https://www.youtube.com/embed/6GanW1yMQpo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p className="want_to_join_parts_undertitle">В этом видео вы узнаете, куда распределяются деньги, которые вы получаете по партнёрской программе и как часть из них запустить в работу.</p>

        </div>
        </AnimatedPage>
    )
}