import "./profileStructures.css"
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart"
import AnimatedPage from "../animationPage"
import StructuresListPart from "./strucutresListPart"
import {useState, useRef, useEffect} from "react"
import { apiUrl } from "../../const"
import { useAsyncError } from "react-router-dom"

export default function StructuresList(){
    var requestArr={
        first: [
        ],
    }
    const [invitedArray, setInvitedArray]=useState([

    ])
    const [invitor, setInvitor] = useState([])
    useEffect(()=>{
        const data = new FormData();
        data.append("tel", localStorage.tel);
        data.append("password", localStorage.password);
        data.append("code", localStorage.ID);
        fetch(apiUrl+"/getUsersTable.php", {
            method: "post",
            body: data,
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setInvitedArray(data)
        });

        const datar = new FormData();
        datar.append("tel", localStorage.tel);
        datar.append("password", localStorage.password);
        fetch(apiUrl+"/getFatherTable.php", {
            method: "post",
            body: datar,
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let arr = data.split(",")
            setInvitor(arr)
            console.log(data)
            // if(data=="no"){
                
            // }
            // else{
            //     setInvitor(data)
            //     // setFatherInvited(true)
            //     // let arr = data.split(",")
            //     // setFatherInfo({
            //     //     name:arr[0],
            //     //     ID:arr[1],
            //     //     imgPath:arr[2],
            //     // })
            // }
        });
    },[])
    var invitedArrayPart=[]
    if(invitor.length>0){
        requestArr.first.unshift({
            key: invitor[1],
            invitation:"(пригласивший)",
            name: invitor[0],
            id: invitor[1],
            refLink: invitor[3],
            tel: invitor[2],
        })
    }
    console.log(invitor)
    if(invitedArray.length>0){
        for(let i=0; i<invitedArray.length; i++){
            invitedArrayPart=invitedArray[i].split(",")
            if(invitedArrayPart[4]==""){
                console.log("ky")
            }
            requestArr.first.push({
                key: invitedArrayPart[1],
                invitation:"(приглашенный)",
                name: invitedArrayPart[0],
                id: invitedArrayPart[1],
                refLink: invitedArrayPart[4],
                tel: invitedArrayPart[3],
            })
            // console.log(requestArr)
        }
    }

    return(
        <AnimatedPage>
            <div className="structures_list_container">
                <ArrowButtonStart/>
                {requestArr.first.map(requestArrs =>(
                    <StructuresListPart key={requestArrs.key} invitation={requestArrs.invitation} name={requestArrs.name} id={requestArrs.id} refLink={requestArrs.refLink} tel={requestArrs.tel}/>
                ))}
            </div>
        </AnimatedPage>
    )
}