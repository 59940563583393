import "./profileStructures.css"
import structuresListCopy from "../../img/structuresListCopy.png"
import {useState, useRef, useEffect} from "react"
export default function StructuresListPart(props){
    const MavieRef=useRef(null)
    const MavieRef2=useRef(null)
    const copyButton=useRef(null)
    const copyButton2=useRef(null)
    var allCopies=document.getElementsByClassName("list_part_copyLink")
    const copytext = (e)=>{
        MavieRef.current.select();
        document.execCommand('copy');
        for(let i=0; i<allCopies.length; i++){
            allCopies[i].style.background="rgba(59, 225, 0, 1)"
        }
        e.target.style.background="rgba(86, 26, 211, 0.6)"
    }
    const copytext2 = (e)=>{
        MavieRef2.current.select();
        document.execCommand('copy');
        for(let i=0; i<allCopies.length; i++){
            allCopies[i].style.background="rgba(59, 225, 0, 1)"
        }
        e.target.style.background="rgba(86, 26, 211, 0.6)"
    }
    return(
        <div className="structures_list_part_container">
            <div className="list_part_name_id_box">
                <div className="list_part_name_box">
                    <p className="list_part_name">{props.name}</p>
                    <p className="list_part_invitation">{props.invitation}</p>
                </div>
                <p className="list_part_id">ID {props.id}</p>
            </div>
            <div className="list_part_refLink_box">
                <input readOnly ref={MavieRef} className="list_part_refLink" value={props.refLink==""?"Реф.ссылка не указана":props.refLink}></input>
                <div ref={copyButton} onClick={copytext} className="list_part_copyLink" style={props.refLink==""?{opacity:"0.6", pointerEvents:"none"}:{}}>
                    скопировать
                    <img className="list_part_copyLinkIMG" src={structuresListCopy}/>
                </div>
            </div>
            <div className="list_part_refLink_box">
                <input readOnly ref={MavieRef2} className="list_part_refLink" value={props.tel==""?"Телефон не указан":props.tel}></input>
                <div ref={copyButton2} onClick={copytext2} className="list_part_copyLink" style={props.tel==""?{opacity:"0.6", pointerEvents:"none"}:{}}>
                    скопировать
                    <img className="list_part_copyLinkIMG" src={structuresListCopy}/>
                </div>
            </div>
        </div>  
    )
}