import './App.css';
import RegistrationPage from './pages/registrationPage/registrationPage';
import RegistrationCompletePage from './pages/registrationPage/registrationCompletePage';
import LoginPage from './pages/loginPage/loginPage';
import WelcomePage from './pages/welcomePage/welcomePage';
import DatabaseUltronPage from './pages/databasePage/databaseUltronPage';
import DatabaseMaviePage from './pages/databasePage/databaseMaviePage';
import BurgerMenu from './pages/burgerMenu/burgerMenu';
import WhereToStart from './pages/whereToStart/whereToStart';
import WantToJoin from './pages/wantToJoin/wantToJoin';
import PromotionPage from './pages/promotionPage/promotionPage';
import UltronResources from './pages/ultronResuorcesPage/ultronResources';
import BirgesPage from './pages/birgesPage/birgesPage';
import AnalyticsPage from './pages/analyticsPage/analyticsPage';
import ProfileSettings from './pages/profileSettings/profileSettings';
import WhyJoinUltron from './pages/whereToStartBranch/whyJoinUltron/whyJoinUltron';
import PresentationUltron from './pages/whereToStartBranch/presentationUltron/presentationUltron';
import PromotionHowItWorks from './pages/promotionBranch/promotionHowItWorks/promotionHowItWorks';
import PromotionMarketingMavie from './pages/promotionBranch/promotionMarketingMavie/promotionMarketingMavie';
import PromotionPresentation from './pages/promotionBranch/promotionPresentation/promotionPresentation';
import PromotionSellingPosts from './pages/promotionBranch/promotionSellingPosts/promotionSellingPosts';
import PromotionWhereToStart from './pages/promotionBranch/promotionWhereToStart/promotionWhereToStart';
import PresentationReasonBlockChain from './pages/whereToStartBranch/presentationUltron/presentationReasonBlockChain';
import PresentationEcoSystem from './pages/whereToStartBranch/presentationUltron/presentationEcoSystem';
import PresentationHowMakeMoney from './pages/whereToStartBranch/presentationUltron/presentationHowMakeMoney';
import PresentationPromotionNewbies from './pages/whereToStartBranch/presentationUltron/presentationPromotionNewbies';
import PresentationVebinars from './pages/whereToStartBranch/presentationUltron/presentationVebinars';
import WantToJoinRegister from './pages/wantToJoinBranch/wantToJoinRegister';
import WantToJoinLC from './pages/wantToJoinBranch/wantToJoinLC';
import WantToJoinClubBalance from './pages/wantToJoinBranch/wantToJoinClubBalance';
import WantToJoinOffSteking from './pages/wantToJoinBranch/wantToJoinOffSteking';
import WantToJoinFAQ from './pages/wantToJoinBranch/wantToJoinFAQ';
import WantToJoinGetMoney from './pages/wantToJoinBranch/wantToJoinGetMoney';
import ProfileStructures from './pages/profileStructures/profileStructures';
import StructuresList from './pages/profileStructures/structuresList';
import preloader_logo from "./img/preloader_logo.png"
import DocumentsPage from './pages/documentsPage/documentsPage';
import VideoAutomatization from './pages/whereToStart/videoAutomatization';
import {Routes, Route, useLocation} from "react-router-dom";
import { useEffect, useRef} from "react";
import {AnimatePresence} from "framer-motion";
import KonkursesPage from './pages/konkursesPage/konkursesPage';
import ProfilePage from './pages/profilePage/profilePage';
import SectionContainer from './pages/sectionContainer/sectionContainer';
import ContentContainer from './pages/contentContainer/contentContainer';
import SectionMavieContainer from './pages/sectionContainer/sectionMavieContainer';
import ContentMavieContainer from './pages/contentContainer/contentMavieContainer';
function App() {
  const location=useLocation();
  const preloaderBox=useRef(null)
  useEffect(()=>{
    window.scrollTo({
    top: 0,
    left: 0,
  });
  },[location.pathname])
  var preloaderPlayed;
  if(localStorage.preloaderPlayed){
    preloaderPlayed=localStorage.preloaderPlayed
  }
  else{
    preloaderPlayed="no"
  }
  useEffect(()=>{
    if(preloaderPlayed=="true"){
        preloaderBox.current.style.display="none"
    }
    else{
      setTimeout(() => {
        preloaderBox.current.style.display="none"
        localStorage.setItem("preloaderPlayed","true")
      }, 7300);
    }
  },[])
  return (
    <div className="App">
      <div className="preloader_background" ref={preloaderBox}>
        <div className='light x1'></div>
        <div className='light x4'></div>
        <div className='light x6'></div>
        <div className='light x8'></div>
        <div className='light x9'></div>
        <img className='preloader_logo' src={preloader_logo}/>
          <div className='preloader_title'>
            <p className='first_word'>future</p>
            <p className='sec_word'>of</p>
            <p className='third_word'>defi</p>
          </div>
      </div>
      {/* <div ref={preloaderBox} className="preloader_background">
        <figure >
          <div className='battle-net'></div>
          <div className='battle-net'></div>
          <div className='battle-net'></div>
        </figure>
        <img className='preloader_logo' src={preloader_logo}/>
      </div> */}
      {/* <div className='preloader_box'>
        <p className='preloader_first'>Ultron</p>
        <p className='preloader_second'>Is</p>
        <p className='preloader_third'>Awesome</p>
      </div> */}
      <AnimatePresence wait>
        <Routes>
          <Route path="/" element={<LoginPage/>}/>
          <Route path="/registration" element={<RegistrationPage/>}/>
          <Route path="/registrationComplete" element={<RegistrationCompletePage/>}/>
          <Route path="/welcome" element={<WelcomePage/>}/>
          <Route path="/databaseUltron" element={<DatabaseUltronPage/>}/>
          <Route path="/databaseMavie" element={<DatabaseMaviePage/>}/>
          <Route path="/konkurses" element={<KonkursesPage/>}/>
          <Route path="/documents" element={<DocumentsPage/>}/>
          <Route path="/profile" element={<ProfilePage/>}/>
          <Route path="/whereToStart" element={<WhereToStart/>}/>
          <Route path="/wantToJoin" element={<WantToJoin/>}/>
          <Route path="/promotionPage" element={<PromotionPage/>}/>
          <Route path="/promotionHowItWorks" element={<PromotionHowItWorks/>}/>
          <Route path="/promotionMarketingMavie" element={<PromotionMarketingMavie/>}/>
          <Route path="/promotionPresentation" element={<PromotionPresentation/>}/>
          <Route path="/promotionSellingPosts" element={<PromotionSellingPosts/>}/>
          <Route path="/promotionWhereToStart" element={<PromotionWhereToStart/>}/>
          <Route path="/ultronResources" element={<UltronResources/>}/>
          <Route path="/birges" element={<BirgesPage/>}/>
          <Route path="/analytics" element={<AnalyticsPage/>}/>
          <Route path="/profileSettings" element={<ProfileSettings/>}/>
          <Route path="/profileStructures" element={<ProfileStructures/>}/>
          <Route path="/whyJoinUltron" element={<WhyJoinUltron/>}/>
          <Route path="/presentationUltron" element={<PresentationUltron/>}/>
          <Route path="/presentationReasonBlockChain" element={<PresentationReasonBlockChain/>}/>
          <Route path="/presentationEcoSystem" element={<PresentationEcoSystem/>}/>
          <Route path="/presentationHowMakeMoney" element={<PresentationHowMakeMoney/>}/>
          <Route path="/presentationPromotionNewbies" element={<PresentationPromotionNewbies/>}/>
          <Route path="/presentationVebinars" element={<PresentationVebinars/>}/>
          <Route path="/wantToJoinRegister" element={<WantToJoinRegister/>}/>
          <Route path="/wantToJoinLC" element={<WantToJoinLC/>}/>
          <Route path="/wantToJoinClubBalance" element={<WantToJoinClubBalance/>}/>
          <Route path="/wantToJoinOffSteking" element={<WantToJoinOffSteking/>}/>
          <Route path="/wantToJoinFAQ" element={<WantToJoinFAQ/>}/>
          <Route path="/wantToJoinGetMoney" element={<WantToJoinGetMoney/>}/>
          <Route path="/structuresList" element={<StructuresList/>}/>
          <Route path="/videoAutomatization" element={<VideoAutomatization/>}/>
          <Route path="/sectionContainer" element={<SectionContainer/>}/>
          <Route path="/contentContainer" element={<ContentContainer/>}/>
          <Route path="/sectionMavieContainer" element={<SectionMavieContainer/>}/>
          <Route path="/contentMavieContainer" element={<ContentMavieContainer/>}/>
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
