import "./promotionWhereToStart.css"
import AnimatedPage from "../../animationPage"
import ArrowButtonPromotion from "../ArrowButtonPromotion"
import videoPlaceholder from "../../../img/videoPlaceholder.png"
import vosklMark from "../../../img/VosklMark.png"

export default function PromotionWhereToStart(){
    return(
        <AnimatedPage>
        <div className="promotion_where_to_start_container">
            <ArrowButtonPromotion/>
            <p className="promotion_where_to_start_title">Автоматизация с чего начать</p>
            <div className="video_boxer">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/cLQ0gXZuJrM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p className="promotion_where_to_start_purple_text"><b>В этом видео вы узнаете,</b> какие<br/> первые продуктивные шаги нужно<br/> сделать новичку в команде.</p>
            <img className="vosklMark" src={vosklMark}/>
            <p className="promotion_where_to_start_text"><b>Обратите внимание:</b> Проработать логистику<br/> системы, получить результат в компании обкатав<br/> элементы системы, записать видео, обработать<br/> видео, занимает много времени, поэтому цепочку<br/> по обучению наша команда готовит шаг за шагом,<br/> многие пункты еще в разработке.</p>
            <div className="link_to_main_video_promotion">
                <p>👉 Ссылка на главный пост из видео</p>
                <div className="link_to_main_video_promotion_footer">
                    <p>https://t.me/Ultron_Automatic/7</p>
                    <button>смотреть</button>
                </div>
            </div>
            <p className="promotion_where_to_start_text">Информация по подключению<br/> к каналу обучения <b>готовится в следующем<br/> видео по автоматизации</b></p>

        </div>
        </AnimatedPage>
    )
}