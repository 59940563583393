import "./profileSettings.css"
import AnimatedPage from "../animationPage"
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart"
import Burger from "../../components/burger/burger"
import {QRCodeSVG} from 'qrcode.react';
import { useEffect, useState, useRef} from "react";
import { apiUrl } from "../../const";
import QRarrows from "../../img/QRarrows.png"
export default function ProfileSettings(){

    const [passwordsData, setPasswordsData]=useState({
        oldPassword:"",
        newPassword:"",
        confirmPassword:"",
    })
    const [errorPassword, setErrorPassword]=useState("")
    const updatePassword = () => {
        let data = new FormData();
        data.append("tel", localStorage.tel);
        data.append("password", passwordsData.oldPassword);
        data.append("newPassword", passwordsData.newPassword);
        fetch(apiUrl+"/changePassword.php", {
          body: data,
          method: "post",
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            if(data=="ok"){
                alert("Пароль успешно изменен")
                localStorage.password=passwordsData.newPassword
            }
            else{
                alert(data)
            }
          });
    };
    console.log(errorPassword)
    const Validate=()=>{
        if(passwordsData.newPassword.length<6){
            setErrorPassword("Длина пароля должна быть не менее 6 символов")
        }
        else{
            if(passwordsData.newPassword==passwordsData.confirmPassword){
                updatePassword()
            }
            else{
                setErrorPassword("Пароли не совпадают")
            }
        }
    }
    const HandleChange=(e)=>{
        setPasswordsData({...passwordsData,[e.target.name] : e.target.value})
    }
    // const [referalLink, setReferalLink] = useState("")
    const [qrcodeLink, setQrcodeLink]=useState(localStorage.link)
    // const HandleReferalChange=(e)=>{
    //     setReferalLink(e.target.value)
    // }
    // const ChangeReferal=()=>{

    // }
    const LogoRef = useRef(null)
    const firstInput = useRef(null)
    const secInput = useRef(null)
    const buttonIn = useRef(null)
    const forgotPass = useRef(null)
    const forgot = useRef(null)
    useEffect(()=>{
        setTimeout(() => {
            LogoRef.current.style.transform="scale(1)"
            firstInput.current.style.transform="scale(1)"
            secInput.current.style.transform="scale(1)"
            buttonIn.current.style.transform="scale(1)"
            forgotPass.current.style.transform="scale(1)"
            forgot.current.style.transform="scale(1)"
        }, 500);
        
    },[])
    
    return(
        <AnimatedPage>
            <div className="profile_settings_container">
                <ArrowButtonStart/>
                <Burger/>
                <p className="profile_settings_title">Настройки</p>
                <div ref={LogoRef} className="password_box firstInput">
                    <p className="password_box_title">Ваш пароль</p>
                    <input type="password" className="password_box_input" onChange={HandleChange} name="oldPassword" placeholder="*************" value={passwordsData.oldPassword}></input>
                </div>
                <div ref={firstInput} className="password_box secSInput">
                    <p className="password_box_title">Новый пароль</p>
                    <input type="password" className="password_box_input" onChange={HandleChange} name="newPassword" placeholder="*************" value={passwordsData.newPassword}></input>
                </div>
                <div ref={secInput} className="password_box thirdInput">
                    <p className="password_box_title">Подтвердить</p>
                    <input type="password" className="password_box_input" onChange={HandleChange} name="confirmPassword" placeholder="*************" value={passwordsData.confirmPassword}></input>
                </div>
                <button ref={buttonIn} className="change_password" onClick={Validate}>Сменить пароль</button>
                <div ref={forgot} className="qr_code_box_settings">
                    <div className="white_box_for_qrcode">
                        <QRCodeSVG value={qrcodeLink} />
                    </div>
                    <div className="your_qr_code_settings">
                        <p>QR-код будет сгенерирован автоматически</p>
                        <img src={QRarrows}/>
                    </div>
                </div>
                <div className="down_padding"></div>
            </div>
        </AnimatedPage>
    )
}