import "./konkursesPage.css"
import Burger from "../../components/burger/burger"
import MiniProfileIcon from "../../components/miniProfileIcon/miniProfileIcon"
import parachute from "../../img/parachute.png";
import calendar from "../../img/calendar.png";
import moneybag from "../../img/moneybag.png";
import AnimatedPage from "../animationPage";
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart";
import { useEffect, useState, useRef} from "react";

export default function KonkursesPage(){
    const LogoRef = useRef(null)
    const firstInput = useRef(null)
    const secInput = useRef(null)
    const buttonIn = useRef(null)
    const forgotPass = useRef(null)
    useEffect(()=>{
        setTimeout(() => {
            LogoRef.current.style.transform="rotateZ(360deg) scale(1)"
            firstInput.current.style.transform="scale(1)"
            secInput.current.style.transform="scale(1)"
            buttonIn.current.style.transform="scale(1)"
            forgotPass.current.style.transform="scale(1)"
        }, 500);
        
    },[])
    return(
        <AnimatedPage>
        <div className="konkurses_page_container">
            <ArrowButtonStart/>
            <p className="konkurses_page_title">конкурсы</p>
            <img ref={LogoRef} className="konkurses_page_parachute_img" src={parachute}/>
            <p ref={firstInput} className="konkurses_page_text">сегодня мы объявляем конкурс<br/><b>на лучшую презентацию ultron</b></p>
            <div ref={secInput} className="calendar_timer">
                <div className="calendar_box">
                    <img src={calendar}/>
                    <div>
                        <p className="box_title">срок до</p>
                        <p className="box_numbers">31.09.2022</p>
                    </div>
                </div>
                <div className="purple_ear"></div>
            </div>
            <div ref={buttonIn} className="payday">
                <div className="payday_box">
                    <img src={moneybag}/>
                    <div>
                        <p className="box_title">вознаграждение</p>
                        <p className="box_numbers">100 usdt</p>
                    </div>
                </div>
                <div className="purple_ear"></div>
            </div>
            <div ref={forgotPass} className="button_rollin">участвовать</div>
            <div className="down_padding"></div>
        </div>
        </AnimatedPage>
    )
}