import "./StepsBlock.css"
import fourthstepImg from "../../img/cubes.png"
import { useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
export default function StepsBlock(props){
    const Navigate = useNavigate()
    const logoRef = useRef(null)
    useEffect(()=>{
        setTimeout(() => {
            logoRef.current.style.transform="rotateZ(360deg) scale(1)"
        }, 300);
    },[])
    return(
            <div className="steps_block_box" style={{flexDirection: props.index % 2 != 0 && "row-reverse"}} onClick={()=>{Navigate("/sectionMavieContainer?key="+props.ID)}}>
                <img className="steps_block_img" ref={logoRef} src={"https://comeback-team.store/UltronAdminApi/img/" + props.imgBig}/>
                <div className="steps_block_info_box">
                    <p className="steps_block_number">{props.index+1}</p>
                    <p className="steps_block_title">{props.title}</p>
                </div>
            </div>
    )
}