import "./profileStructures.css";
import ArrowButtonStart from "../../components/arrowButtonStart/arrowButtonStart";
import AnimatedPage from "../animationPage";
import profilePhoto from "../../img/profilephoto.png";
import structuresBranch3 from "../../img/structuresBranch.png";
import structuresBranch2 from "../../img/structuresBranch2.png";
import structuresBranch from "../../img/structuresBranch3.png";
import { useState, useRef, useEffect } from "react";
import { apiUrl } from "../../const";
import { Link } from "react-router-dom";

export default function ProfileStructures() {
  const [invitedArray, setInvitedArray] = useState([]);
  const [fatherInvited, setFatherInvited] = useState(false);
  const [fatherInfo, setFatherInfo] = useState({
    name: "",
    ID: "",
    imgPath: "",
  });
  const [imgPath, setImgPath] = useState("");
  useEffect(() => {
    const data = new FormData();
    data.append("tel", localStorage.tel);
    data.append("password", localStorage.password);
    data.append("code", localStorage.ID);
    fetch(apiUrl + "/getUsers.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setInvitedArray(data);
      });

    console.log(invitedArray);
    if (localStorage.imgPath) {
      setImgPath(localStorage.imgPath);
    }

    fetch(apiUrl + "/getFather.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data == "no") {
        } else {
          setFatherInvited(true);
          let arr = data.split(",");
          setFatherInfo({
            name: arr[0],
            ID: arr[1],
            imgPath: arr[2],
          });
        }
      });
    console.log(invitedArray);
  }, []);
  return (
    <AnimatedPage>
      <div className="profile_structures_container">
        <ArrowButtonStart />
        <p className="structures_title">структура</p>
        <div
          className="who_invited_you_box"
          style={fatherInvited ? { display: "flex" } : { display: "none" }}
        >
          <div className="who_invited_you_photo">
            <img
              src={
                fatherInfo.imgPath.length > 0
                  ? fatherInfo.imgPath
                  : profilePhoto
              }
            />
          </div>
          <div className="who_invited_you_names_box">
            <p className="who_invited_you">вас пригласил</p>
            <div className="who_invited_you_name_ID">
              <p className="who_invited_you_name">{fatherInfo.name}</p>
              <p className="who_invited_you_ID">КОД {fatherInfo.ID}</p>
            </div>
          </div>
        </div>
        <div className="structures_main_content">
          <div className="main_content_upper">
            <div className="you_photo">
              <img src={imgPath.length > 0 ? imgPath : profilePhoto} />
            </div>
            <div className="structure_downloader">
              <p className="structure_downloader_text">
                Вы можете <br /> посмотреть всю
                <br /> свою структуру
              </p>
              <Link
                to="/structuresList"
                className="structure_downloader_button"
              >
                смотреть
              </Link>
            </div>
          </div>
          <div
            className="main_content"
            style={
              invitedArray.length == "0"
                ? { display: "none" }
                : { display: "flex" }
            }
          >
            <p className="main_content_web_you">вы</p>
            <img
              className="structuresBranch"
              src={
                invitedArray.length == 1
                  ? structuresBranch
                  : invitedArray.length == 2
                  ? structuresBranch2
                  : structuresBranch3
              }
            />
            <div
              className="invited_peoples"
              style={
                invitedArray.length == 2
                  ? { width: "62.5%" }
                  : { width: "100%" }
              }
            >
              {invitedArray.length > 0 &&
                invitedArray.map((elem, index) => {
                  if (index <= 2) {
                    let data = elem.split(",");
                    return (
                      <div className="invited_about">
                        <div className="invited_photo">
                          <img
                            src={data[2].length > 0 ? data[2] : profilePhoto}
                          />
                        </div>
                        <p className="invited_name">{data[0]}</p>
                        <p className="invited_ID">ID {data[1]}</p>
                      </div>
                    );
                  }
                })}
            </div>
            <div
              className="how_much_invited"
              style={
                invitedArray.length > 3
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <div className="how_much_invited_title">
                партнеров в вашей структуре:
              </div>
              <div className="how_much_invited_amount">
                {invitedArray.length}
              </div>
            </div>
          </div>
          <div
            className="no_invited_box"
            style={
              invitedArray.length == "0"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <p className="main_content_web_you">вы</p>
            <p className="no_invited">
              У вас еще нет приглашенных пользователей
            </p>
          </div>
        </div>
        <div className="down_padding"></div>
      </div>
    </AnimatedPage>
  );
}
