import "./whereToStartPart.css"
import firststepImg from "../../img/manstandingontable.png"
import { Link } from "react-router-dom"
import { useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";

export default function WhereToStartPart(props){
    const Navigate=useNavigate();
    
    const LogoRef = useRef(null)

    // useEffect(()=>{
    //     setTimeout(() => {
    //         LogoRef.current.style.transform="rotateZ(360deg) scale(1)"
    //     }, 500);
    // },[])
    return(
        <div onClick={()=>Navigate("/contentContainer?key="+props.ID)} className="where_to_start_part_container">
            <img ref={LogoRef} style={{transitionDelay:props.delay}} src={"https://comeback-team.store/UltronAdminApi/img/" + props.imgSrc}/>
            <div className="start_part_info">
                <p>{props.title}</p>
                <button>смотреть</button>
            </div>
            <div className="start_part_right_year"></div>
        </div>
    )
}