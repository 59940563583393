import "./presentationUltron.css"
import videoPlaceholder from "../../../img/videoPlaceholder.png"
import ArrowButtonPresentation from "./arrowButtonPresentation"
import AnimatedPage from "../../animationPage"
export default function PresentationEcoSystem(){
    return(
        <AnimatedPage>
        <div className="presentations_container">
        <ArrowButtonPresentation/>
            <div className="presentation_titles">Эко система блокчейна Ultron</div>
            <iframe width="99%" height="198" src="https://www.youtube.com/embed/R31iDU07RG4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p className="presentations_text">Узнайте о команде, которая <br/> сформированная вокруг <br/> блокчейна Ultron.</p>
            <p className="presentations_next_text">некоторые большие направления блокчейна <b>делегированы отдельным компаниям, которые специалисты в теме.</b></p>
        </div>
        </AnimatedPage>
    )
}