import "./whereToStart.css"
import AnimatedPage from "../animationPage"
import ArrowButtonWhereToStart from "../whereToStartBranch/arrowButtonWhereToStart/arrowButtonWhereToStart"

export default function VideoAutomatization(){
    return(
        <AnimatedPage>
        <div className="video_automatization_container">
            <ArrowButtonWhereToStart/>
            <p className="presentation_titles">Видео об автоматизации</p>
            <iframe width="100%" height="198" src="https://www.youtube.com/embed/ZtpXsw3V330" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p className="presentations_text">Видео об Автоматизации находится в производстве.</p>
        </div>
        </AnimatedPage>
    )
}