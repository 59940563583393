import "./arrowButtonDataBase.css"
import arrowImg from "../../img/arrowback.png"

export default function ArrowButtonDataBase(){
    const WindowBack=()=>{
        window.history.back()
    }
    return(
        <div className="arrow_button_data_base_container" onClick={WindowBack}>
                <img src={arrowImg}/>
                <p>база знаний</p>
        </div>
    )
}