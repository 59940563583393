import "./promotionMarketingMavie.css"
import AnimatedPage from "../../animationPage"
import ArrowButtonPromotion from "../ArrowButtonPromotion"
import marketingMavieImg from "../../../img/birgesImg.png"
import marketingMavieSteps from "../../../img/marketingMavieSteps.png"
import mavieIcon from "../../../img/resourcesSquareImg6.png"
export default function PromotionMarketingMavie(){
    return(
        <AnimatedPage>
        <div className="promotion_marketing_mavie_container">
            <ArrowButtonPromotion/>
            <p className="promotion_marketing_mavie_title">маркетинг mavie</p>
            <img className="marketingMavieImg" src={marketingMavieImg}/>
            <div className="work_in_team_first_steps">
                <div className="marketingMavieSteps">
                    <img src={marketingMavieSteps}/>
                    <p className="marketingMavieSteps1 marketingMavieSteps_numbers">1</p>
                    <p className="marketingMavieSteps2 marketingMavieSteps_numbers">2</p>
                    <p className="marketingMavieSteps3 marketingMavieSteps_numbers">3</p>
                </div>
                <p className="work_in_team_first_steps_text">работа с командой первые шаги</p>
                <a href="https://t.me/c/1775208220/11" className="work_in_team_first_steps_button">перейти к записи</a>
            </div>
            <div className="work_in_team_first_steps_bottom">
                <div className="work_in_team_steps_bottom">
                    <img className="mavieIcon" src={mavieIcon}/>
                    <p className="work_in_team_steps_text">Маркетинг <br/> компании</p>
                    <p className="work_in_team_steps_text_versions">версия 1</p>
                    <a href="https://t.me/c/1775208220/7" className="work_in_team_first_steps_button">перейти к записи</a>
                </div>
                <div className="work_in_team_steps_bottom">
                    <img className="mavieIcon" src={mavieIcon}/>
                    <p className="work_in_team_steps_text">Маркетинг <br/> компании</p>
                    <p className="work_in_team_steps_text_versions">версия 2</p>
                    <a href="https://t.me/c/1775208220/12" className="work_in_team_first_steps_button">перейти к записи</a>
                </div>
            </div>
        </div>
        </AnimatedPage>
    )
}