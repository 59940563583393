import "./wantToJoin.css"
import ArrowButtonDataBase from "../../components/arrowButtonDataBase/arrowButtonDataBase";
import AnimatedPage from "../animationPage";
import FirstStepStart from "../../components/firstStepStart/firstStepStart";
import SecondStepStart from "../../components/secondStepStart/secondStepStart";
import ThirdStepStart from "../../components/thirdStepStart/thirdStepStart";
import FifthStepStart from "../../components/fifthStepStart/fifthStepStart";
import SixthStepStart from "../../components/sixthStepStart/sixthStepStart";
import StepsBlock from "../../components/stepsBlock/StepsBlock";
import numberOneImg from "../../img/numberOne.png";
import numberTwoImg from "../../img/numberTwo.png";
import numberThreeImg from "../../img/numberThree.png";
import numberFourImg from "../../img/numberFour.png";
import numberFiveImg from "../../img/numberFive.png";
import numberSixImg from "../../img/numberSix.png";
import wantJoin1 from "../../img/wantJoin1.png";
import wantJoin2 from "../../img/wantJoin2.png";
import wantJoin3 from "../../img/wantJoin3.png";
import wantJoin4 from "../../img/wantJoin4.png";
import wantJoin5 from "../../img/wantJoin5.png";
import wantJoin6 from "../../img/wantJoin6.png";
import { Link } from "react-router-dom";


export default function WantToJoin(){
    var requestArr={
        sections: [
            {
                id: 0,
                title: "Регистрация и оплата монет",
                number: "1",
                imgBig: wantJoin1,
                link: "/wantToJoinRegister",
                flexDirection: "row",
            },
            {
                id: 1,
                title: "Как вывести USDT на кошелек",
                number: "2",
                imgBig: wantJoin2,
                link: "/wantToJoinGetMoney",
                flexDirection: "row-reverse",
            },
            {
                id: 2,
                title: "Обзор кабинета Mavie.Global",
                number: "3",
                imgBig: wantJoin3,
                link: "/wantToJoinLC",
                flexDirection: "row",
            },
            {
                id: 3,
                title: "Club Balance: Как он работает",
                number: "4",
                imgBig: wantJoin4,
                link: "/wantToJoinClubBalance",
                flexDirection: "row-reverse",
            },
            {
                id: 4,
                title: "Частые вопросы и ответы",
                number: "5",
                imgBig: wantJoin5,
                link: "/wantToJoinFAQ",
                flexDirection: "row",
            },
            {
                id: 5,
                title: "Как отключить авто стейкинг",
                number: "6",
                imgBig: wantJoin6,
                link: "/wantToJoinOffSteking",
                flexDirection: "row-reverse",
            },
        ],
    }
    return(
        <AnimatedPage>
        <div className="want_to_join_container">
            <ArrowButtonDataBase/>
            <p className="want_to_join_title">хочу вступить</p>
            {requestArr.sections.map(requestArrs =>(
                <StepsBlock key={requestArrs.id} title={requestArrs.title} number={requestArrs.number} imgBig={requestArrs.imgBig} link={requestArrs.link} flexDirection={requestArrs.flexDirection}/>
            ))}
            <div className="down_padding"></div>
        </div>  
        </AnimatedPage> 
    )
}