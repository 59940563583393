import "./wantToJoinBranch.css"
import ArrowButtonWantToJoin from "./arrowButtonWantToJoin"
import AnimatedPage from "../animationPage"
import videoPlaceholder from "../../img/videoPlaceholder.png"
export default function WantToJoinGetMoney(){
    return(
        <AnimatedPage>
        <div className="want_to_join_containersBig">
            <ArrowButtonWantToJoin/>
            <p className="want_to_join_parts_title">обзор кабинета mavie.global</p>
            <iframe width="99%" height="173" src="https://www.youtube.com/embed/Z1V48CnE1qU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <p className="want_to_join_parts_undertitle">Для команды подготовили обзор кабинета с привязкой ко времени и ссылкам. <br/> <b>дата обзора 24.08.2022</b></p>
            <p className="want_to_join_bold_text">Были разобраны следующие <br/> разделы кабинета:</p>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                00:01 - 1:45

                </p>
                <p className="white_answer_getmoney">
                Верхний блок кабинета - Тут вы можете попасть в <br/> настройки, перейти на оф канал системы, активировать<br/> чат для переписки с партнёрами, колокольчик<br/> оповещение кабинета, найти реф ссылку.
                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                1:45 - 6:35

                </p>
                <p className="white_answer_getmoney">
                My Teame -  Страница Моя команда: Разобрали 5 разделов аналитики построения сети. Очень хорошо проработанная система поиска партнёров и их достижений.                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                6:35 - 15:45

                </p>
                <p className="white_answer_getmoney">
                Home - Главная страница: Промо пригласи 5 человек, промо на Дубай, аналитика бонусов, ранги.
                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                15:45 - 19:30

                </p>
                <p className="white_answer_getmoney">
                Earnings - Страница Мой Заработок: Разобрали как заводить, выводить доход, начисления с каждой проплаты, 4 раздела доходов.                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                19:30 - 20:20

                </p>
                <p className="white_answer_getmoney">
                Staking HUB -  Страница покупки Стейкинг Хабов: Тут вы можете приобрести хаб с монетами
со 100$ до 300.000$.                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                22:10 - 22:22

                </p>
                <p className="white_answer_getmoney">
                Orders - Страница с Ордерами: Тут видны все ваши покупки и даты.                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                20:20 - 22:10

                </p>
                <p className="white_answer_getmoney">
                My HUB's -  Страница с монетами ULX. Мои Хабы: Полный разбор стейкинга, реинвестов, вывод с кабинета Ulx, аналитика купленных хабов по датам.                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                22:10 - 22:22

                </p>
                <p className="white_answer_getmoney">
                Orders - Страница с Ордерами: Тут видны все ваши покупки и даты.                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                22:22 - 25:10

                </p>
                <p className="white_answer_getmoney">
                Ranking -  Страница с Рангами: Здесь вы узнаете на каком ранге Вы находитесь и сколько Вам осталось до следующего ранга. Также можете забрать бонусы деньгами до 1.500.000$                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                25:10 - 25:37

                </p>
                <p className="white_answer_getmoney">
                Resources - Страница с Ответами на Вопросы: Тут все ресурсы компании, промо материалы, ответы на вопросы.                </p>
            </div>
            <div className="question_box_getmoney">
                <p className="purple_question_getmoney">
                с 25:37

                </p>
                <p className="white_answer_getmoney">
                Главная страница маркетинговой системы Mavie: Здесь вы ознакомитесь с другими компаниями маркетинговой системы.                </p>
            </div>
            <p className="want_to_join_bold_text">Этот инфо блок ответит на сотни мелких вопросов в вашей команде, а в это время вы займетесь маркетингом.</p>



            <div className="down_padding"></div>
            <div className="scroll_down_container">
                <div className="scroll_down">
                    Листайте вниз
                </div>
            </div>
        </div>
        </AnimatedPage>
    )
}